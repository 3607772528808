export function cloneExecutableScript(scriptEl) {
  if (scriptEl.tagName !== 'SCRIPT') {
    throw new Error('Not a script element');
  }
  const newScript = document.createElement('script');
  for (const {
    name,
    value
  } of Array.from(scriptEl.attributes)) {
    newScript.setAttribute(name, value);
  }
  newScript.innerHTML = scriptEl.innerHTML;
  if (!scriptEl.async && scriptEl.src) {
    newScript.async = false;
  }
  return newScript;
}
export function parseNodesFromEmbedHTML(html) {
  const blankResult = {
    domNodes: [],
    layoutLoading: Promise.resolve([]),
    linksLoading: Promise.resolve([])
  };
  if (!html) return blankResult;
  const parser = new DOMParser();
  const parsedDoc = parser.parseFromString(html, 'text/html');
  const {
    scriptAndLinkNodes,
    imagesLoading,
    linkPromises
  } = extractNodesAndHandleStylesheets(parsedDoc.head);
  const result = scriptAndLinkNodes.concat(...Array.from(parsedDoc.body.childNodes));
  return {
    domNodes: result,
    layoutLoading: Promise.allSettled([...linkPromises, ...imagesLoading]),
    linksLoading: Promise.allSettled(linkPromises)
  };
}
const addProtocol = link => link.startsWith('//') ? `$https:${link}` : link;

/**
 * Wordpress sometimes runs the block inside of an iframe (e.g., in site-editor) whose protocol is "blob:"
 * And sometimes, embeds will be output with protocol-less URLs,
 * which will then get resolved to blob://. To resolve this, we make sure that all URLs
 * have https: on them so they don't pick up the protocol of their parent frame.
 */
const updateNodeProtocol = node => {
  const imgScriptTag = node instanceof HTMLImageElement || node instanceof HTMLScriptElement;
  const linkTag = node instanceof HTMLLinkElement;
  if (imgScriptTag && node.src) {
    node.src = addProtocol(node.src);
  }
  if (linkTag && node.href) {
    node.href = addProtocol(node.href);
  }
};
function extractNodesAndHandleStylesheets(startNode) {
  const linkPromises = [];
  const imagesLoading = [];
  const scriptAndLinkNodes = Array.from(startNode.childNodes).reduce((acc, node) => {
    const isStyleNode = node instanceof HTMLLinkElement && node.rel === 'stylesheet';
    updateNodeProtocol(node);
    if (node.nodeName === 'SCRIPT' || node.nodeName === 'STYLE' || node.nodeName === 'META') {
      acc.push(node);
      return acc;
    }
    if (node.nodeName === 'IMG' && node instanceof HTMLImageElement) {
      imagesLoading.push(new Promise((resolve, reject) => {
        node.addEventListener('load', () => resolve());
        node.addEventListener('error', () => reject(new Error()));
      }));
    }
    if (isStyleNode) {
      linkPromises.push(new Promise((resolve, reject) => {
        node.addEventListener('load', () => resolve());
        node.addEventListener('error', () => reject(new Error()));
      }));
      acc.push(node);
      return acc;
    }
    return acc;
  }, []);
  return {
    scriptAndLinkNodes,
    imagesLoading,
    linkPromises
  };
}