const hasOwnProperty = (obj, key) => {
  return Object.prototype.hasOwnProperty.call(obj, key);
};
const attachPropToObject = (obj = {}, key = '', value, options = {}) => {
  const [head, ...rest] = key.split('.');
  if (rest.length === 0) {
    if (!hasOwnProperty(obj, key) || options.overwrite) {
      obj[key] = value;
    }
  } else {
    if (!hasOwnProperty(obj, head) || hasOwnProperty(obj, head) && !obj[head]) {
      obj[head] = {};
    }
    attachPropToObject(obj[head], rest.join('.'), value, options);
  }
};
export default attachPropToObject;